import React, { useState, useEffect } from 'react';
import { Col, Row, Carousel, Modal, Drawer } from 'antd';
import { ApiHelpers } from '../helpers';
import i18n from '../config/i18n';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseConfig } from '../config';
import { t } from 'i18next';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Offer = (props) => {
    const { restaurantToken, width } = props;
    const [offerList, setOfferList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCarouselItem, setSelectedCarouselItem] = useState({});
    const [type, setType] = useState("");
    const sliderSettings = {
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,
        centerPadding: "20px",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
    }

    const definitions = {
        event: { title: t('ID_POFTIGO_EVENTS_LABEL'), img: "eventImage", description: "eventDescription" },
        offer: { title: t('ID_POFTIGO_OFFERS_LABEL'), img: "promoImage", description: "promoDescription" }
    }

    useEffect(() => {
        if (restaurantToken)
            ApiHelpers.getRestaurantDetails(restaurantToken, i18n.language).then(
                (response) => {
                    if (!response.data.message) {
                        setOfferList(response.data.promotionsList);
                        setEventList(response.data.eventList);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }, [restaurantToken])

    const showModal = (item, type) => {
        logEvent(analytics, 'select_content', {
            content_type: 'promotionAndEvent',
            item_id: type,
            page_title: restaurantToken,
        });
        setIsModalVisible(true);
        setSelectedCarouselItem(item);
        setType(type);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const modalContent =
        <>
            {selectedCarouselItem[type.img] ? (
                <img
                    src={ApiHelpers.getImageLink(selectedCarouselItem[type.img])}
                    alt={selectedCarouselItem.title}
                    title={selectedCarouselItem.title}
                    className="menuItemImage modal"
                />
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: selectedCarouselItem[type.description] }}></div>

        </>


    const carouselContent = (d, type, index) => <Row className="restaurantMenuContainer offerContainer" key={"carousel-" + type + "-" + index}>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 6 }} onClick={() => showModal(d, type)}>
            <div className="menuCategoryItem">
                <h2>{d.title}</h2>
                <div className="tagOffers">{type.title}</div>
                {d[type.img] ? (
                    <img
                        src={ApiHelpers.getImageLinkSize(d[type.img], 'medium')}
                        alt={d.title}
                        title={d.title}
                        className="menuItemImage offerImage"
                    />
                ) : null}

            </div>
        </Col>
    </Row>


    return (
        offerList ?
            <>
                <Carousel {...sliderSettings} className={`${offerList.length + - eventList.length > 1 ? "carousel-full-width" : ""}`}>
                    {offerList.length > 0 && offerList.map((d, index) => {
                        return carouselContent(d, definitions.offer, index)
                    })}
                    {eventList.length > 0 && eventList.map((d, index) => {
                        return carouselContent(d, definitions.event, index)
                    })}
                </Carousel>
                {width > 600 ? (
                    <Modal
                        title={selectedCarouselItem.title}
                        visible={isModalVisible}
                        closable={true}
                        height={window.innerHeight - 200}
                        footer={null}
                        maskClosable={true}
                        onCancel={handleCancel}>
                        {modalContent}
                    </Modal>
                ) : (
                    <Drawer
                        title={selectedCarouselItem.title}
                        placement="bottom"
                        closable={true}
                        onClose={handleCancel}
                        visible={isModalVisible}
                        height={window.innerHeight - 150}>
                        {modalContent}
                    </Drawer>
                )}

            </> : null
    );
};

export default Offer;
